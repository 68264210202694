import serverLogger from 'utils/serverLogger';

const { mapNamedLocaleToLocale } = require('../data/localsMap');

const getTranslatedSegmentSync = (segment, locale = 'en') => {
  let segmentTranslation = '';
  let localeTranslationFile = null;

  try {
    localeTranslationFile = require(`../public/static/locales/${mapNamedLocaleToLocale[locale]}/routes.json`)
  } catch (error) {
    serverLogger.warn(
      `[Inside getTranslatedSegment]==> locale(${locale}) is missing routes.json file`,
    );
  }

  if (localeTranslationFile && localeTranslationFile[segment]) {
    segmentTranslation = localeTranslationFile[segment];
  } else {
    serverLogger.warn(
      `[Inside getTranslatedSegment]==> translation for ${segment} not found....fallbacking the segment itself`,
    );
    segmentTranslation = segment;
  }

  return segmentTranslation;
};

module.exports = getTranslatedSegmentSync;
