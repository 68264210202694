import { mapNamedLocaleToLocale } from 'data/localsMap';
import getTranslatedSegmentSync from 'utils/getTranslatedSegmentSync';

const { envVars } = require('next/config').default().publicRuntimeConfig;

const { EN_DOMAIN } = envVars;
const { API_PROTOCOL } = envVars;

const generateHreflang = (localizedUrl, url, host, homePage = false, translate = true) => {
  const finalPathName = localizedUrl.split('?')[0];
  const data = {
    canonical: `https://${host}${finalPathName}`,
    urls: [],
  };
  const urlSegments = url.split('/');

  const getTranslatedUrl = (local) => {
    let translatedUrl = '';
    urlSegments.forEach((segment, index) => {
      if (index > 0) {
        const translatedSegemnt = translate ? getTranslatedSegmentSync(segment, local) : segment;
        translatedUrl += `/${translatedSegemnt || ''}`;
      }
    });
    return translatedUrl;
  };

  Object.entries(mapNamedLocaleToLocale).forEach(([namedLocale, standardLocale]) => {
    let strucuredPath = '';
    if (!homePage) {
      strucuredPath = getTranslatedUrl(namedLocale);
    }
    const finalURL = `${API_PROTOCOL}://${EN_DOMAIN}${
      namedLocale !== 'en' ? `/${namedLocale}` : ''
    }${strucuredPath}`;
    const lang = {
      url: finalURL,
      hreflang: standardLocale === 'en' ? 'x-default' : standardLocale,
    };
    data.urls.push(lang);
  });
  return data;
};
export default generateHreflang;
