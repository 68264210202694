const generateMetaData = (metaData) => (
  <>
    <title>{metaData.title}</title>
    <meta name="description" content={metaData.description} />
    <meta name="keywords" content={metaData.keywords} />
    <meta property="og:title" content={metaData.og_title} />
    <meta property="og:description" content={metaData.og_description} />
    <meta property="og:image" content={metaData.og_image} />
  </>
);

export default generateMetaData;
